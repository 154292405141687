* {
  font-family: 'Times New Roman';
}

html,
body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Exo', sans-serif;
}

.elements {
  color: white;
  width: 90%;
  margin: 5%;
  /* border: solid red; */
}


#root {
  width: 100%;
  height: 100%;
  background: #000;
  position: relative;
  background: url(./images/bg1.jpg);
  background-size: cover;
}

@media screen and (max-width:1060px) {
  #root {
    width: 100%;
    height: 100%;
    background: #000;
    position: relative;
    background: url(./images/bg2.jpg);
    background-size: cover;
  }

  .elements {
    /* text-align:center; */
    display: inline;
    word-break: normal;
    word-wrap: break-word;
    /* flex-direction: column; */
  }

  
}