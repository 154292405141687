#Projects_container {
    /* position: relative; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: url(../../images/bg1.jpg);
    background-size: cover;
    overflow: hidden;
    z-index: 0;
}

#ViewProject{
    width:100%;
    height: 100%;
    /* margin: 0 10% 0 10%; */
    position: relative;
    overflow-y: scroll;

    background: rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8.8px);
    overflow-y: scroll;
}

#exitButton{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.inViewProject::-webkit-scrollbar{
    width: 10px;
}

.inViewProject::-webkit-scrollbar-thumb{
    background:#59c6b1;
    border-radius: 20px;
}


.inViewProject2{
    display: none;
}

.inViewProject {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 85%;
    margin: auto;
    /* border: solid yellow; */
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(8.8px);
    overflow-y: scroll;
}


.inViewProject * {
    color: white;
}

.inViewProject h1 {
    text-align: center;
    font-size: 50px;
}

.screenShotList {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    /* border: solid red; */
}

.screenShotList img {
    width: 30%;
    height: 600px;
    margin: auto;
}

.icons_list {
    display: flex;
    flex-direction: row;
    width: 30%;
    margin: auto;
    padding: 3%;
}

.screenShotList_CA{
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    /* border: solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icons {
    margin: auto;
    width: 35px;
}

.section_left {
    width: 33%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    /* border: solid red; */
    display: flex;
    flex-direction: column;
    color: white;
}

.section_rigth {
    margin: auto 0 auto auto;
    width: 30%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background: #0bda0e; */
    /* border: solid green; */
}

#projects_one {
    width: 256px;
    height: 144px;
    margin: 20px 0 20px 0;
    border-radius: 20px;
    background: url(../../images/MINI_STEAM.png);
    background-size: cover;
    /* left:0 */
}

#projects_two {
    width: 256px;
    height: 144px;
    margin: 20px 0 20px 0;
    border-radius: 20px;
    background: url(../../images/SUPER.png);
    background-size: cover;
    /* position: absolute; */
}

#projects_three {
    width: 256px;
    height: 144px;
    margin: 20px 0 20px 0;
    border-radius: 20px;
    background: url(../../images/CARS_AUGMENTED.png);
    background-size: 100%;
    /* position: absolute; */
}



#titleone {
    width: 100%;
    /* border: solid green; */
    text-align: left;
    /* padding-left: 40px; */
    font-size: 5px;
    /* border: solid red; */
    margin-top: 14%;
}

#web_style {
    display: inline;
    color: #59c6b1;
    margin:0;
    padding: 0;
}

.langages {
    width: 100%;
    height: 90px;
    /* border: solid green; */
    display: flex;
    flex-direction: row;
}

.langages p {
    color: #59c6b1;
    margin: auto;
}

#titlePP{
    font-size: 14px;
}

/*animation title svg, chat gpt control*/

#Projects text {
    fill: transparent;
    stroke: #59c6b1;
    stroke-width: 2;
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: draw 4s cubic-bezier(0.35, 0, 0.25, 1) forwards, fill 2s cubic-bezier(0.35, 0, 0.25, 1) 1.4s forwards;
    font-size: 90px;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 100%;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    0% {
        fill: transparent;
    }

    100% {
        fill: #59c6b1;
    }
}

#Projects text:last-child {
    animation: draw 4s cubic-bezier(0.35, 0, 0.25, 1) forwards, fill 2s cubic-bezier(0.35, 0, 0.25, 1) 1.4s forwards, fill-last 1.2s cubic-bezier(0.35, 0, 0.25, 1) 4.2s forwards;
}


@media screen and (max-width:1060px) {

    #Projects_container {
        position: absolute;
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        background: url(../../images/bg2.jpg);
        background-size: cover;
        bottom: 0;
        /* background-attachment:fixed; */
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 0;
        /* glass effect */

        background: rgba(0, 0, 0, 0.19);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(8.8px);
    }

    .section_left {
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-bottom: auto;
        /* border: solid red; */
        display: flex;
        flex-direction: column;
        color: white;
        /* overflow: scroll; */
    }

    .section_rigth {
        width: 100%;
        height: fit-content;
        /* background: #0bda0e; */
    }


    #titlePP {
        font-size: 12px;
    }

    #section_container {
        height: 100%;
    }

    #titleone {
        margin-bottom: 0;
    }

    .screenShotList{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .screenShotList img{
        width: 200px;
        height: 400px;
        margin-top: 20px;
    }

    .inViewProject{
        display: none;
    }

    .inViewProject2 {
        /* position: absolute; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 600px;
        margin-top: 50px;
        /* border: solid yellow; */
        /* From https://css.glass */
        background: rgba(0, 0, 0, 0.19);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(8.8px);
        overflow-y: scroll;
    }
    
    
    .inViewProject2 * {
        color: white;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .inViewProject2 p{
        width: 100%;
        word-break: keep-all;
        word-wrap:break-word;
    }
    
    .inViewProject2 h1 {
        text-align: start;
        margin-left: 20px;
        font-size: 30px;
    }

    .screenShotList_CA{
        margin-top: 30px;
        width: 100%;
        height: fit-content;
        /* border: solid red; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .screenShotList_CA img{
        width: 300px;
        height: 150px;
        margin-top: 20px;
        /* margin: 20px 40px 0 40px; */
    }
   

}