#navigation {
    width: 100%;
    height: 10%;
    background: #00000000;
    position: absolute;
    /* border: solid red; */
    display: flex;
    color: #59c6b1;
}

#navigation_left {
    width: 65%;
    height: 100%;
    /* background: green; */
    margin: auto;
}

#navigation_rigth {
    width: 33%;
    height: 100%;
    display: flex;
    /* background: yellow; */
    margin: auto;
}

.navItems{
    margin: auto;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #59c6b1;
}

.navItems:hover {
    background: url(../../images/loading.svg);
    background-size: cover;
}

.menue {
    display: none;
}

@media screen and (max-width:1060px) {

    #navigation_rigth {
        width: 33%;
        height: 100%;
        display: flex;
        /* background: yellow; */
        margin: auto;
    }

    #navigation_rigth a {
        display: none;
    }

    #name{
        font-size: 10px;
        margin: 10px;
        display: flex;
        /* align-items:start; */
        justify-content: start;
        z-index: 1000;
    }

    .menue {
        display: flex;
        width: 30px;
        height: 30px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: 10px;
    }

    #close_menue {
        background: url(../../images/fermer.png);
        background-size: cover;
        z-index: 1001;
    }

    #open_menue {
        background: url(../../images/menu.png);
        background-size: cover;
        z-index: 1001;
    }

    #nav_responsive {
        position: absolute;
        right: 0;
        width:30%;
        min-width:150px;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        height: 100%;
        background: rgb(0, 0, 0);
        z-index: 1000;
        border-left:solid 1px #59c6b1 ;
    }

    .link{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        /* border: solid red; */
        margin: auto 0 auto 0;
    }

    .link a{
        color: white;
        /* border: solid yellow; */
        font-size: 15px;
        /* margin: 20px auto 20px auto; */
        margin: 20px auto 20px auto;
    }

    a:hover {
        background: #00000000;
    }

    .social{
        margin: 0 auto 0 auto;
        width: 100%;
        height: 80px;
        /* border: solid red; */
        display: flex;
    }

    .social a{
        width: fit-content;
        height: fit-content;
        margin: auto;
    }

}