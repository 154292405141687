#container {
    width: 100%;
    height: 100%;
    background: url(../../images/bg1.jpg);
    background-size: cover;
}

.styled-list {
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 50% 50%;
    grid-template-areas: 
    "one two three"
    "four five six";
    /* border: solid red; */
    border-radius: 10px;
}

.styled_h2{
    color:white;
    /* border:solid #59c6b1; */
    width: fit-content;
    height: 30px;
    margin: auto auto 0 20px;
}

.styled-list p{
    font-size: 14px;
    width: fit-content;
    border: green;
    color: white;
    /* display: inline-block;
    margin-left: 10px; */
}

.one{
    grid-area: one;
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.two{
    grid-area: two;
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.three{
    grid-area: three;
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.four{
    grid-area: four;
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.five{
    grid-area: five;
    width: 100%;
    height: 100%;
    /* background: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#cv_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
    /* border: solid red; */
    position: absolute;
    bottom: 0;
    overflow: hidden;
}

.icons_langage{
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* border: solid red; */
}

/* espace de gauche */

#container_left {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 90%;
    /* border: solid green; */
    margin: auto;
}

.left_section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100px;
    /* border: solid blue; */
    margin: auto;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.8px);
    -webkit-backdrop-filter: blur(4.8px);
    border-radius: 10px;
    z-index: 999;
}


/* espace de droite */

#container_right {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 90%;
    /* border: solid yellow; */
    margin: auto;
}

.projets {
    display: flex;
    flex-direction: row;
    /* border: solid mediumvioletred; */
    width: 98%;
    height: 45%;
    margin: auto;
    color: white;
}

.projet {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 90%;
    /* border: solid rgb(244, 179, 0); */
    margin: auto;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.8px);
    -webkit-backdrop-filter: blur(4.8px);
    border-radius: 10px;
    z-index: 999;
}

.projet h2{
    /* border: solid; */
    text-align: center;
    width: 90%;
    margin: 10px auto auto auto;
    color: #59c6b1;
}

.projet p {
    width: 90%;
    font-size: 14px;
    margin:20px auto auto auto;
    /* border: solid; */
}

.projet section{
    margin: auto auto 10px auto;
    /* border: solid red; */
    width: 100%;
    height: fit-content;
}

.projet section p {
    margin: auto;
}

.projet section img {
    margin: auto;
    width: 25px;
    height: 25px;
}


#education{
    /* border: solid red; */
    display: flex;
    flex-direction: column;
}


/* ******************** */

h4{
    color: #59c6b1;
    font-size: 14px;
    margin: auto auto auto 10px;
}

h5{
    color: white;
    font-size: 14px;
    margin: 5px auto 10px 10px;
}

#download_resume{
   margin: auto;
   width: 90%;
}

.puce{
    width: 40px;
    height: 40px;
    border-radius:50%;
    position: absolute;
    bottom: -10px;
    right: -10px;
}

@media screen and (max-width:1060px){

    #container {
        width: 100%;
        height: 100%;
        background: url(../../images/bg2.jpg);
        background-size: cover;
    }
    

    #cv_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* border: solid red; */
        position: absolute;
        bottom: 0;
        overflow: scroll;
    }

    #container_right {
        display: block;
        width: 100%;
        margin: auto;
    }
    

    .projets {
        display: block;
        /* border: solid mediumvioletred; */
        width: 100%;
        height:fit-content;
        color: white;
        padding-bottom: 10px;
    }

    #container_left {
        display: block;
        width: 100%;
        height: fit-content;
        /* border: solid green; */
        margin: auto;
    }
    
    .left_section {
        width: 90%;
        height: 110px;
        display: flex;
        flex-direction: column;
        
        /* border: solid #59c6b1; */
        /* border: solid blue; */
        margin: 30px auto 30px auto;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.8px);
        -webkit-backdrop-filter: blur(4.8px);
        border-radius: 10px;
        z-index: 999;
    }
    
    
    .projet {
        display: grid;
        grid-template-rows: 20% 60% 20%;
        grid-template-columns: 100%;
        grid-template-areas: 
        "one"
        "two"
        "three";
        width: 90%;
        height: 200px;
        /* border: solid rgb(244, 179, 0); */
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.8px);
        -webkit-backdrop-filter: blur(4.8px);
        border-radius: 10px;
        z-index: 999;
    }
    
    h2 {
        /* margin-bottom: auto; */
        margin: 0;
        grid-area: one;
        width: 100%;
        color: #59c6b1;
        font-size: 20px;
        /* border: solid yellow; */
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .projet p {
        margin: 0;
        grid-area: two;
        font-size: 15px;
        /* border: solid yellow; */
        display:block;
        /* align-items: center; */
        /* justify-content: center; */
        word-wrap: normal;
        width: 100%;
        margin: auto;
    }
    
    section {
        margin: 0;
        /* border: solid yellow; */
        grid-area: three;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    
    section img {
        margin: auto;
        width: 30px;
        height: 30px;
    }
    
    
    #education,#expertise,#Langage,#Loisirs{
        /* border: solid red; */
        display: flex;
        flex-direction: column;
        width: 80%;
    }
    
    
    /* ******************** */
    
    #education_section_left,#education_section_right{
        display: flex;
        width: 95%;
        height: 43%;
        /* border: solid green; */
        margin: auto;
    }
    
    #MathSup_left,#Ece_left{
        width: 20%;
        height: 95%;
        /* border: solid yellow; */
        margin: auto;
    }
    
    #MathSup_right,#Ece_right{
        display: flex;
        flex-direction: column;
        width: 75%;
        height: 95%;
        /* border: solid rgb(173, 252, 2); */
        margin: auto;
    }
    
    h4{
        color: white;
        width: 90%;
        font-size: 15px;
    }
    
    h5{
        width: 90%;
        color: darkgray;
        font-size: 13px;
        margin: 5px auto 5px auto;
    }
    

    #download_resume{
        display: block;
        margin: auto;
        width: 50%;
    }
}