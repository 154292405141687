.aboutMeContainer {
    width: 100%;
    height: 90%;
    /* border: solid yellow; */
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aboutCard {
    width: 40%;
    height: 80%;
    border: solid #59c6b1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.8px);
    -webkit-backdrop-filter: blur(7.8px);
}

.aboutCard section {
    margin: auto;
}

.profilePicture{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    opacity: 0.8;
    margin: auto;
}

.aboutProfiledescription {
    width: 100%;
    /* border: solid green; */
    /* display: flex; */
}

.aboutProfiledescription p{
    width: 90%;
    height: 90%;
    font-size: 16px;
    color: white;
    margin: auto;
    display: block;
}

.aboutContact {
    width: 100%;
    /* height: 10%; */
    /* border: solid mediumvioletred; */
    display: flex;
    flex-direction: row;
}

.aboutContact a{
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.aboutContact img{
    width: 30px;
    height: 30px;
}





@media screen and (max-width:1060px) {
    .aboutMeContainer {
        width: 100%;
        height: 90%;
        /* border: solid yellow; */
        position: absolute;
        /* bottom: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .aboutCard {
        width: 90%;
        height: 90%;
        border: none;
    }

    .aboutProfiledescription {
        width: 100%;
        height: fit-content;
        /* border: solid green; */
        display: block;
        /* flex-direction: column; */
    }

    .aboutProfiledescription p{
        width: 90%;
        height: fit-content;
        font-size: 14px;
        color: white;
        margin: auto;
        display: block;
    }

    .aboutContact {
        width: 100%;
        height: 10%;
        /* border: solid mediumvioletred; */
        display: flex;
        flex-direction: row;
    }

    .aboutProfilePicture {
        width: 100%;
        height: 100px;
        /* border: solid yellow; */
        display: flex;
        /* margin-bottom: 15px; */
    }
    
    .aboutProfilePicture img{
        width: auto;
        height: 100px;
        border-radius: 50%;
        opacity: 0.8;
        margin: auto;
    }
}