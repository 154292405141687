#web{
    display: inline;
    color: #59c6b1;
}

.CA_img{
  width: 60%;
  margin: 20px 0 20px 0;
  border-radius:20px;
}

#group{
    width: 100%;
    height: 100%;
    background: url(../../images/bg1.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
}

#left{
    width: 33%;
    height: 100%;
    /* border: solid red; */
    display: flex;
    flex-direction: column;
    color: white;
    /* border:solid red; */
}

#left h1{
    width: 100%;
    /* border: solid green; */
    text-align: left;
    /* padding-left: 40px; */
    font-size: 50px;
}

.screenShotList_DSH{
  width: 100%;
  display: flex;
  flex-direction: row;
  /* border: solid red; */
}

.screenShotList_DSH img{
  /* padding: 15px; */
  width: 20%;
  height: auto;
  /* margin: auto; */
}

#section_container{
    width: 90%;
    /* border: solid green; */
    display: flex;
    flex-direction: column;
    margin: auto;
}

#button_section{
    width: 100%;
    display: flex;
    flex-direction: row;
    /* border: solid rgb(239, 14, 14); */
    padding-top: 60px;
    margin: auto;
}

.button{
    font-size: 14px;
    font-weight: bold;
    width: 150px;
    height: 50px;
    /* border-radius: 10px; */
    background: #00000000;
    color: #59c6b1;
    border: solid #59c6b1;
    margin: auto;
}

.button a{
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Project text {
    fill: transparent;
    stroke: #59c6b1;
    stroke-width: 2;
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: draw 4s cubic-bezier(0.35, 0, 0.25, 1) forwards, fill 2s cubic-bezier(0.35, 0, 0.25, 1) 1.4s forwards;
    font-size: 90px;
    font-family: Arial;
  }
  
  @keyframes draw {
    0% {
      stroke-dashoffset: 100%;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes fill {
    0% {
      fill: transparent;
    }
    100% {
      fill: #59c6b1;
    }
  }
  
  #Project text:last-child {
    animation: draw 4s cubic-bezier(0.35, 0, 0.25, 1) forwards, fill 2s cubic-bezier(0.35, 0, 0.25, 1) 1.4s forwards, fill-last 1.2s cubic-bezier(0.35, 0, 0.25, 1) 4.2s forwards;
  }


@media screen and (max-width:1060px){
    #group{
        width: 100%;
        height: 100%;
        background: url(../../images/bg2.jpg);
        background-size: cover;
        display: flex;
        flex-direction: column;
    }

  

    #left{
        width: 100%;
        height: 100%;
        /* border: solid red; */
        display: flex;
        flex-direction: column;
        color: white;
        
        /* border:solid red; */
    }

    #left h1{
        font-size: 50px;
        /* display: none; */
    }
}

